<template>
	<Common
		type="areas"
		@cardClick="redirect"
		@getData="get"
		:list="infoAreas"
		:isLoading="isLoading"
		:meta="meta"
	/>
</template>

<script>
import Common from '../modules/list/Common.vue'
import { createRequest } from '@/api/requestBuilder'
import requestConfigs from '@/api/requestConfigs'
import { setCommonMetaData } from '@/utils/setMetaData'
import { i18n } from '@/i18n'

export default {
	name: 'Areas',
	components: { Common },
	setup() {
		setCommonMetaData(`${i18n.global.t('titlePages.areas')} - OWRealty`, {
			description: i18n.global.t('meta.areasDesc'),
			type: 'article',
			title: i18n.global.t('meta.areasTitle'),
			url: window.location.href
		})
	},
	data() {
		return {
			isLoading: true,
			infoAreas: [],
			meta: {},
			processingRequest: false
		}
	},
	methods: {
    get(fresh = false) {
			if (this.processingRequest) return

			this.processingRequest = true

			createRequest(requestConfigs.GETRegionList, {
				queryPayload: {
					per_page: 24,
          page: fresh ? 1 : (this.meta.current_page || 0) + 1,
          order_by: this.$route.query.order_by ?? 'sort',
          direction: this.$route.query.direction ?? 'asc'
				}
			})
				.then((result) => {
          if (fresh) {
            this.infoAreas = result.response.items
          } else {
					  this.infoAreas.push(...result.response.items)
          }
					this.meta = result.response.meta
				})
				.finally(() => {
					this.isLoading = false
					this.processingRequest = false
				})
		},
		redirect(_, payload) {
			this.$router.push({
				name: 'SoloArea',
				params: { slug: payload, lang: this.$store.state.uiLanguage }
			})
		}
	},
	beforeMount() {
		this.get()
	}
}
</script>
